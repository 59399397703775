
import ApiService from "@/core/services/ApiService"
import { computed, defineComponent, onMounted, ref, watch } from "vue"
import { Edit } from "@element-plus/icons-vue"
import BusinessComponent from "@/components/admin/definitions/business/business.vue"
import { useRoute, useRouter } from "vue-router"
import swalConfirm from "@/core/helpers/swal-confirm"
import { SelectModel } from "@/core/models/SelectModel"

export default defineComponent({
  name: "admin-business-list",
  components: {
    BusinessComponent,
  },
  props: ["add"],
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    const businessList = ref([])
    const accountList = ref<SelectModel[]>([])
    const activeAccountId = ref<string | undefined>()
    const filterOnlyActives = ref(true)
    const isLoading = ref(true)
    const newBusiness = ref(false)
    const activeBusinessId = ref<string | undefined>()

    watch(
      () => route.params.business_id,
      newValue => {
        activeBusinessId.value = newValue as string
      }
    )

    watch(
      () => props.add,
      newValue => {
        newBusiness.value = true == newValue
      }
    )

    const businessListFiltered = computed(() => {
      return businessList.value.filter(x => {
        return x["isActive"] == true || !filterOnlyActives.value
      })
    })

    const getAccountList = async () => {
      const { data } = await ApiService.get("account")
      accountList.value = data.map(x => ({ name: x.title, id: x.id }))
    }

    const getBusinessList = async id => {
      if (id != undefined) {
        isLoading.value = true
        const { data } = await ApiService.get(`account/${id}/business`)

        businessList.value = data
        isLoading.value = false
      }
    }

    const onAccountChange = async () => {
      await getBusinessList(activeAccountId.value)
    }

    const handleBranchDrawerClose = () => {
      router.push({ name: "admin-business-list" })
    }

    const removeBusiness = async businessId => {
      const confirmResult = await swalConfirm()
      if (confirmResult.isConfirmed) {
        await ApiService.get("business/remove/" + businessId)
        // await getBusinessList()
      }
    }

    onMounted(async () => {
      activeBusinessId.value = route.params.business_id as string
      newBusiness.value = props.add
      // await getBusinessList()
      await getAccountList()
    })

    return {
      businessList,
      accountList,
      Edit,
      filterOnlyActives,
      getBusinessList,
      getAccountList,
      onAccountChange,
      businessListFiltered,
      isLoading,
      handleBranchDrawerClose,
      removeBusiness,
      activeBusinessId,
      activeAccountId,
      newBusiness,
    }
  },
})
